<div>
    <vl-region v-if="viewModelLoading">
        <vl-layout>
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
            </div>
        </vl-layout>
    </vl-region>

    <vl-region v-if="organisationInvoiceData">
        <vl-form-grid mod-stacked>
            <vl-column>
                <vl-title tag-name="h2">Betalingsinstellingen</vl-title>
            </vl-column>
            <template v-if="organisationInvoiceData.hasUnpaidInvoices">
                <vl-column width-l="12">
                    <vl-alert mod-warning icon="alert-circle"
                              title="Let op! Deze organisatie heeft achterstallige betalingen en mag in principe niet op factuur betalen." />
                </vl-column>
            </template>
            <vl-column>
                <kl-description-list>
                    <template v-for="(value, key) in organisationInvoiceData.identificators">
                        <kl-description-list-row :label="key + ':'">{{ value }}</kl-description-list-row>
                    </template>

                    <kl-description-list-row label="Athumi-ID:">{{athumiActor.athumiId}}</kl-description-list-row>

                    <kl-description-list-row v-if="fetchingAthumiData">
                        <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
                    </kl-description-list-row>
                </kl-description-list>

                <template v-if="!fetchingAthumiData">
                    <kl-organisation-actor
                        :model-value="athumiActor"
                        :show-email="!organisationPaysByInvoice"
                        :has-athumi-id="hasValidAthumiId"
                        :kbo-number="organisationInvoiceData.kboNumber"
                        :route-admin-organisation-name="routeAdminOrganisationDetail.name"
                        @update:model-value="onSaveActor"/>
                </template>

            </vl-column>

            <vl-column v-if="hasValidAthumiId">
                <kl-description-list>
                    <kl-description-list-row label="Peppol:">{{peppolInfo}}</kl-description-list-row>
                </kl-description-list>
            </vl-column>

            <vl-column>
                <kl-description-list>
                    <kl-description-list-row label="Prepaid saldo:" v-if="!organisationInvoiceData.financialServiceAvailable">
                        Het saldo van uw organisatie kon niet opgehaald worden. Probeer het later nogmaals opnieuw.
                    </kl-description-list-row>
                    <kl-description-list-row label="Prepaid saldo:" v-if="organisationInvoiceData.financialServiceAvailable && prepaidAmount">
                        {{ prepaidAmount }}
                    </kl-description-list-row>
                </kl-description-list>
            </vl-column>

            <template v-if="organisationInvoiceData.hasInvoiceData && hasValidAthumiId">
                <vl-column>
                    <vl-checkbox :modelValue="organisationPaysByInvoice" @update:modelValue="toggleCheckbox" mod-block mod-switch
                                 :mod-disabled="!isAdmin"> Deze
                        organisatie en suborganisaties mogen op factuur betalen</vl-checkbox>
                </vl-column>
                <vl-column width-s="2" width-m="6" width-l="8"></vl-column>
                <vl-column>
                    <vl-button @click="saveOrganisationPaysByInvoice" :mod-loading="isSending"
                               :mod-disabled="!organisationPaysByInvoiceChanged" mod-large>Bewaar veranderingen</vl-button>
                </vl-column>
                <vl-column width-s="2" width-m="6" width-l="8"></vl-column>
            </template>
            <template v-else>
                <vl-column width-s="2" width-m="6" width-l="8"></vl-column>
                <vl-column>
                    <vl-button @click="onOpenSwitchToInvoiceModal" :mod-disabled="switchToInvoiceDisabled" mod-large>Overzetten naar betaling op factuur</vl-button>
                </vl-column>
            </template>
        </vl-form-grid>
        <vl-modal :id="togglePaysByInvoiceModal" :closable="true">
            <vl-title v-bind:tag-name="'h3'">Bent u zeker dat {{organisationDetail.organisationName}}
                <template v-if="subOrganisationsCount > 0"> &amp; zijn {{subOrganisationsCount}}
                    suborganisatie(s)</template> vanaf nu online moeten betalen</vl-title>
            <vl-action-group>
                <vl-button @click="switchToOnlinePayment" mod-large :mod-loading="switchingToOnlinePayment"
                           :mod-disabled="switchingToOnlinePayment" :mod-error="!switchingToOnlinePayment">Ja, Zet om naar
                    online</vl-button>
                <vl-button class="vl-button--link" @click="cancelSwitchToOnlinePayment"
                           v-vl-modal-toggle="'toggle-pays-by-invoice'" icon="cross" mod-icon-before>Annuleer</vl-button>
            </vl-action-group>
        </vl-modal>

        <vl-modal :id="invoiceDataModal" :closable="true" mod-medium>
            <vl-title tag-name="h3">Overzetten naar betaling op factuur</vl-title>
            <vl-u-spacer mod-medium />
            <vl-typography>
                Deze instellingen worden toegepast op alle suborganisaties van de organisatie. Deze kan u achteraf
                aanpassen in de instellingen van de suborganisaties.
            </vl-typography>
            <vl-u-spacer mod-medium />
            <vl-form-grid mod-stacked>
                <vl-column>
                    <!--                    <vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }">-->
                    <form class="vl-form" @submit="onSubmit">
                        <vl-title tag-name="h5">Facturatiegegevens</vl-title>
                        <vl-form-grid mod-stacked>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <kl-field-validation-wrapper :field="emailField" mod-required #default="{hasErrors}"
                                                             annotation="Wordt gebruikt om de facturen naar op te sturen.">
                                    <vl-input-field name="E-mail" v-model="organisationInvoiceData.email"
                                                    :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <kl-field-validation-wrapper :field="attentionField" #default="{hasErrors}">
                                    <vl-input-field name="Ter attentie van"
                                                    v-model="organisationInvoiceData.attention" :mod-error="hasErrors"
                                                    mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <kl-field-validation-wrapper :field="referenceField" #default="{hasErrors}"
                                                             annotation="Optioneel veld op uw volgende factuur. Vul bv. een interne bestelbon- of PO-nummer in.">
                                    <vl-input-field name="Eigen Referentie"
                                                    v-model="organisationInvoiceData.reference" :mod-error="hasErrors"
                                                    mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                        </vl-form-grid>
                        <vl-u-spacer mod-medium />
                        <vl-title tag-name="h5">Contactpersoon i.v.m. facturatie</vl-title>
                        <vl-u-spacer mod-small />
                        <vl-typography>Dit is enkel te zien voor contactpuntmedewerkers.</vl-typography>
                        <vl-u-spacer mod-small />
                        <vl-form-grid mod-stacked>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <kl-field-validation-wrapper :field="invoiceNameField" #default="{hasErrors}">
                                    <vl-input-field name="invoice-name"
                                                    v-model="organisationInvoiceData.invoiceName" :mod-error="hasErrors"
                                                    mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <kl-field-validation-wrapper :field="invoiceEmailField" #default="{hasErrors}">
                                    <vl-input-field name="invoice-email"
                                                    v-model="organisationInvoiceData.invoiceEmail" :mod-error="hasErrors"
                                                    mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width-s="12" width-m="8" width-l="8">
                                <kl-field-validation-wrapper :field="invoicePhoneNumberField" #default="{hasErrors}">
                                    <vl-input-field name="invoice-phone" placeholder="+32"
                                                    v-model="organisationInvoiceData.invoicePhoneNumber" :mod-error="hasErrors"
                                                    mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                        </vl-form-grid>
                        <vl-u-spacer mod-medium />
                        <vl-button type="submit" :mod-disabled="disableSubmit"
                                   :mod-loading="isSending" mod-large>Bewaar facturatiegegevens</vl-button>
                    </form>
                    <!--                    </vl-form-validation-observer>-->
                </vl-column>
            </vl-form-grid>
        </vl-modal>
    </vl-region>
</div>
