<div :class="classes">
  <!-- Confirm reception -->
  <template v-if="canConfirm">
    <vl-button mod-large :mod-loading="confirmSending" :mod-disabled="viewingAsImpersonatedUser" @click="confirm">Bevestig ontvangst</vl-button>
  </template>
  <!-- /Confirm reception -->

  <!-- Answer -->
  <template v-else-if="(isStateConfirmed
  || isStateConfirmedUploadedImkl
  || isStateConfirmedInvalidImkl)
  && !isArchived && isUnaWriter">
    <!-- Loading  -->
    <template v-if="isProcessing && !isValidating && !showUpload">
      <vl-region>
        <vl-layout>
          <div v-vl-align:center>
            <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
          </div>
        </vl-layout>
      </vl-region>
    </template>

    <!-- Invalid Imkl uploaded -->
    <template v-if="isStateConfirmedInvalidImkl">
      <vl-alert mod-error icon="alert-circle" title="Uw pakket is niet gevalideerd">
        <vl-typography>
          Uw pakket voldoet niet aan de <a :href="imklRequirementsLink" target="_blank">IMKL-vereisten</a>.
        </vl-typography>
        <vl-button icon="text-redo" @click="replaceIMKL" :mod-disabled="viewingAsImpersonatedUser" mod-icon-before>IMKL-pakket vervangen</vl-button>
      </vl-alert>
      <kl-steps v-if="validationSteps" :steps="validationSteps" />
    </template>

    <!-- Imkl not yet uploaded or Imkl uploaded but validating -->
    <template v-if="isStateConfirmed || isStateConfirmedUploadedImkl">
      <!-- Show Imkl upload -->
      <template v-if="showUpload">
        <template v-if="hasNamespace">
          <!-- Show Imkl upload in new upload or in Validating state -->
          <kl-upload-imkl @revert="cancelUpload" :status="status" :imklUploadUrl="imklUploadUrl" :isValidating="isValidating" :isImklTest="false" :mod-disabled="viewingAsImpersonatedUser" mod-revertable mod-bordered />
        </template>
        <!-- Show namespace message in case no namespace exists -->
        <template v-else>
          <vl-alert mod-warning icon="warning" title="Nog geen namespace toegekend aan uw zone">
            <vl-typography>Zolang er voor de zone <strong>{{zoneName}}</strong> geen namespace toegekend is, kan u geen IMKL-pakket
              opladen voor deze zone.</vl-typography>
            <vl-typography v-if="isUnaManager">Ken de namespaces voor uw zone(s) toe in <kl-router-link protect
                :to="unaSettingsLocation">uw instellingen</kl-router-link>.</vl-typography>
            <vl-typography v-else>U hebt hier geen rechten toe. Contacteer uw beheerder om namespaces aan uw zones toe te kennen.</vl-typography>
            <vl-button mod-icon-before icon="nav-left" @click="showUpload = false">Terug</vl-button>
          </vl-alert>
        </template>
      </template>
      <!-- Answer -->
      <template v-if="canAnswer">
        <vl-title tag-name="h2" tag-style="h3">Kies uw antwoord</vl-title>
        <vl-action-group>
          <vl-button class="kl-una-answer__button" :mod-loading="verifyingNamespace" :mod-disabled="notInvolvedSending || viewingAsImpersonatedUser"
            mod-large @click="verifyNamespace">
            IMKL-pakket opladen</vl-button>
          <vl-button class="kl-una-answer__button" :mod-disabled="verifyingNamespace || viewingAsImpersonatedUser" mod-large mod-tertiary
            :mod-loading="notInvolvedSending" @click="notInvolved">Ik heb geen kabels en leidingen in deze zone
          </vl-button>
        </vl-action-group>
      </template>
    </template>
  </template>
  <!-- /Answer -->

  <!-- Answer -->
  <template v-else-if="isStateNotInvolved">
    <vl-alert mod-success icon="check" title="Planaanvraag beantwoord">
      <vl-typography>
        U antwoordde <span v-if="hasDateLastStatusChange">op {{dateLastStatusChange}}&nbsp</span>dat u geen kabels en leidingen in de planaanvraagzone beheert.
        <template v-if="isArchived">Deze planaanvraag is
          intussen gearchiveerd.</template><br>
          Toch foutieve of onvolledige info doorgestuurd? Contacteer dan rechtstreeks de planaanvrager.
      </vl-typography>
      <vl-u-spacer mod-small />
      <kl-una-maprequest-archive-download v-if="isArchived" v-slot:actions :maprequest-id="mapRequestId" :zone-id="zoneId" :download-url="downloadArchiveUrl" />
    </vl-alert>
  </template>
  <!-- /Answer -->

  <template v-else-if="isStateResponded">
    <vl-alert mod-success icon="check" title="Planaanvraag beantwoord">
      <template v-if="!isArchived">
        <vl-typography>
            U antwoordde <span v-if="hasDateLastStatusChange">op {{dateLastStatusChange}}&nbsp</span>met
            <vl-link :href="downloadImklUrl">dit IMKL-pakket</vl-link>.
            <template v-if="!mapAssetsLoading && !mapAssetsReady"><br> Vanaf dat het plannenpakket beschikbaar is voor de planaanvrager, kan u hier uw
            antwoord op kaart bekijken.</template><br>
            Toch foutieve of onvolledige info doorgestuurd? Contacteer dan rechtstreeks de planaanvrager.
        </vl-typography>
        <vl-u-spacer mod-small />
        <vl-button v-if="!mapAssetsLoading && mapAssetsReady" @click="navigateToViewer" class="kl-una-answer__button no-focus-outline">
          Bekijk uw antwoord voor deze zone op kaart
        </vl-button>&nbsp;
        <vl-button v-if="!mapAssetsLoading && mapAssetsReady" @click.prevent="fetchDownloadKlipPackageUrl" mod-secondary class="kl-una-answer__button no-focus-outline">
          Download .klip-bestand
        </vl-button>
      </template>
      <template v-else>
        <vl-typography>
            U antwoordde <span v-if="hasDateLastStatusChange">op {{dateLastStatusChange}}&nbsp</span>met een IMKL-pakket. Deze planaanvraag is intussen gearchiveerd.
        </vl-typography>
      </template>
      <kl-una-maprequest-archive-download v-if="isArchived" v-slot:actions :maprequest-id="mapRequestId"
        :zone-id="zoneId" :download-url="downloadArchiveUrl" />
    </vl-alert>
  </template>

  <template v-else-if="isStateNotAnswered && !isArchived">
    <vl-alert mod-error icon="alert-circle" title="Te laat!">
      <vl-typography>
        U bent al meer dan een maand te laat om deze planaanvraag te beantwoorden. U kan niet meer antwoorden via
        KLIP.<br />
        Contacteer zo snel mogelijk de planaanvrager om de plannen rechtstreeks te bezorgen.
      </vl-typography>
    </vl-alert>
  </template>

  <template v-else-if="isStateShouldRespondToMri">
    <vl-alert mod-success icon="check" title="Planafhandeling rechtstreeks">
      <vl-typography v-if="!isArchived">
        De planaanvraag wordt rechtstreeks afgehandeled.
      </vl-typography>
      <vl-typography v-else>De planaanvraag werd rechtstreeks afgehandeld. Deze is intussen gearchiveerd.</vl-typography>
      <kl-una-maprequest-archive-download v-if="isArchived" v-slot:actions :maprequest-id="mapRequestId"
        :zone-id="zoneId" :download-url="downloadArchiveUrl" />
    </vl-alert>
  </template>

  <template v-else-if="(isStateAssigned || isStateNotAnswered || isStateConfirmed) && isArchived">
    <vl-alert mod-error icon="alert-circle" title="Te laat!">
      <vl-typography>U was meer dan een maand te laat om deze planaanvraag te beantwoorden. Deze planaanvraag is intussen
        gearchiveerd.</vl-typography>
      <kl-una-maprequest-archive-download v-if="isArchived" v-slot:actions :maprequest-id="mapRequestId"
        :zone-id="zoneId" :download-url="downloadArchiveUrl" />
    </vl-alert>
  </template>
</div>
