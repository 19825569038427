import {createApp} from 'vue';
import App from './app/app.vue';
import { createPinia } from 'pinia';
import webComponents from '@/plugins/webComponents';
import ipproComponents from '@/plugins/ipproComponents';
import globalComponents from '@/plugins/globalComponents';
import directives from '@/plugins/directives-vue3';
import signalrHub from '@/plugins/signalr';
import proxyClient from '@/plugins/proxy-client';
import validations from './plugins/validations-vue3';
import filters from './plugins/filters';
import externalComponents from '@/plugins/external-components';
import routerPlugin, {klipRouter} from '@/router/router-vue3';
import progressbarPlugin from '@/plugins/progressbarPlugin';
import vlMigrationComponents from '@/plugins/vl-migration-components';
import ga4Plugin from '@/plugins/ga4Plugin';

const app = createApp(App);

// TO VERIFY: Vue3 migration problem
// ref: https://stackoverflow.com/questions/69055857/preserve-whitespace-between-spans-in-vue
// > dit hoort ervoor te zorgen dat whitespace in een <span> bewaart blijft (volgens standaard html specs)
// > maar dit lijkt niet 100% te werken
app.config.compilerOptions.whitespace = 'preserve';

app.use(webComponents);
app.use(vlMigrationComponents);
app.use(globalComponents);
app.use(externalComponents);
app.use(ipproComponents);
app.use(directives);

app.use(signalrHub);
app.use(proxyClient);
app.use(routerPlugin);
app.use(progressbarPlugin);
app.use(filters);
app.use(validations);
app.use(ga4Plugin, { router: klipRouter });

const pinia = createPinia();
app.use(pinia);


// remove this loop when all components are migrated to vue3 without compatMode
for (const component in app._context.components) {
    if (component.startsWith('vl-')) {
        (app._context.components[component] as any).compatConfig = {
            MODE: 3
        }
    }
}

app.mount('#app');

export default app;
