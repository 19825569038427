import {klipIndex} from '@/router/klip-index';
import {EUserRole} from '@/app/shared/state/user-role';
import {RouteRecordRaw} from 'vue-router';

export const pdaIndex: RouteRecordRaw = {
    path: '/pda',
    name: 'pda',
    component: () => import(/* webpackChunkName: "pdazone" */ './index.vue'),
    meta: {
        authorize: { roles: [EUserRole.pda_manager] },
        breadcrumb: { title: '', parent: klipIndex },
    },
};

export const pdaZoneCreate: RouteRecordRaw = {
    path: '/pda/zone/create',
    name: 'pdazonecreate',
    component: () => import(/* webpackChunkName: "pdazone" */ './zone-create_edit.vue'),
    meta: {
        authorize: { roles: [EUserRole.pda_manager] },
        breadcrumb: { title: 'Nieuwe zone', parent: pdaIndex },
    },
};

export const pdaZoneId: RouteRecordRaw = {
    path: '/pda/zone/:zoneId',
    name: 'pdazone',
    component: () => import(/* webpackChunkName: "pdazone" */ './zone.vue'),
    meta: {
        authorize: { roles: [EUserRole.pda_manager] },
        breadcrumb: { title: 'Zone detail', parent: pdaIndex },
    },
};

export const pdaMapRequest: RouteRecordRaw = {
    path: '/pda/zone/:zoneId/maprequest/:id',
    name: 'pdamaprequest',
    component: () => import(/* webpackChunkName: "pdazone" */ './map-request.vue'),
    meta: {
        authorize: { roles: [EUserRole.pda_manager] },
        breadcrumb: { title: 'Planaanvraag', parent: pdaZoneId },
    },
};

export const pdaZoneEdit: RouteRecordRaw = {
    path: '/pda/zone/:zoneId/edit',
    props: true,
    name: 'pdazoneedit',
    component: () => import(/* webpackChunkName: "pdazone" */ './zone-create_edit.vue'),
    meta: {
        authorize: { roles: [EUserRole.pda_manager] },
        breadcrumb: { title: 'Bewerk zone', parent: pdaZoneId },
    },
};

export const pdaZoneOverview: RouteRecordRaw = {
    path: '/pdazoneoverview',
    redirect: pdaIndex,
};

export const newPdaZone: RouteRecordRaw = {
    path: '/newpdazone',
    redirect: pdaZoneCreate,
};

export default [pdaIndex, pdaZoneCreate, pdaZoneId, pdaZoneEdit, pdaZoneOverview, pdaMapRequest, newPdaZone];
