<vl-region>
    <div v-vl-align:center v-if="loading">
        <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
    </div>
    <vl-form-grid mod-stacked v-if="!loading">
        <vl-column>
            <vl-title tag-name="h2">
                {{title}}
            </vl-title>
        </vl-column>
        <vl-column>
            <div>
                Elke eerste dag van de maand sturen we automatisch een e-mail naar alle organisaties die
                een 'Eigen referentie' ingesteld hebben bij hun facturatiegegevens in KLIP. In deze e-mail vragen we
                om hun facturatiegegevens (eigen referentie, t.a.v. en e-mailadres) na te kijken en indien nodig aan te
                passen voor de 10de van de maand.
            </div>
        </vl-column>
        <vl-column v-if="roundSuccessfullyDone">
            <vl-alert icon="check" title="Facturatie ronde afgelopen!" mod-success>
                <div v-if="!!currentInvoiceRound.endTime">De facturatieronde die gestart is op
                    {{formatDate(currentInvoiceRound.startTime)}} is succesvol afgelopen op
                    {{formatDate(currentInvoiceRound.endTime)}}.</div>
                <div v-else>De facturatieronde die gestart is op {{formatDate(currentInvoiceRound.startTime)}} is
                    succesvol afgelopen.</div>
            </vl-alert>
            <vl-u-spacer mod-medium />
        </vl-column>
        <vl-column v-if="nothingToInvoice">
            <vl-alert icon="check" title="Niks te factureren!" mod-success>
                <div>De facturatieronde heeft geen te factureren aanvragen.</div>
            </vl-alert>
            <vl-u-spacer mod-medium />
        </vl-column>

        <vl-column v-if="error">
            <vl-alert mod-error icon="alert-circle" title="Fout tijdens het starten van de facturatie!"
                :closable="false">
                <p>
                    {{error}}
                </p>
            </vl-alert>
            <vl-u-spacer mod-medium />
        </vl-column>
        <vl-column v-kl-u-background-alt v-if="!invoiceFormVisible && roundSuccessfullyDone">
            <vl-grid v-kl-u-box-padding>
                <vl-column width="9">
                    <strong>Volgende geplande facturatieronde</strong>
                    <div>{{next11thDay}} 02u00</div>
                    <vl-u-spacer mod-small />
                    <div v-if="!!mapRequestsNotRegisteredInFinancialTransaction &&
                            mapRequestsNotRegisteredInFinancialTransaction.length > 0">
                        <div><strong>LET OP!</strong> Er zijn {{mapRequestsNotRegisteredInFinancialTransaction.length}}
                            planaanvragen die nog
                            <kl-router-link :to="routeAdminMapRequestWithFinancialAnomalies" class="vl-link">
                                niet binnen Clearing House geregistreerd zijn.
                            </kl-router-link>
                        </div>
                    </div>
                </vl-column>
                <vl-column width="3">
                    <strong><vl-button @click="showInvoiceForm" mod-secondary :mod-disabled="startingNewRound"
                            mod-large>
                            Manuele facturatie starten
                        </vl-button>
                    </strong>
                </vl-column>
            </vl-grid>
        </vl-column>
        <vl-column v-kl-u-background-alt v-if="!invoiceFormVisible && roundSuccessfullyDone">
            <vl-grid v-kl-u-box-padding>
                <vl-column>
                    <strong>Vorige facturatieronde</strong>
                    <div v-if="!!currentInvoiceRound.endTime">
                        <div>Gestart op {{formatDate(currentInvoiceRound.startTime)}}.</div>
                        <div>Succesvol afgerond op {{formatDate(currentInvoiceRound.endTime)}}.</div>
                    </div>
                    <div v-else>De facturatieronde die gestart is op {{formatDate(currentInvoiceRound.startTime)}} is
                        succesvol afgelopen.</div>
                </vl-column>
            </vl-grid>
        </vl-column>
        <vl-column>
            <vl-form-grid mod-stacked v-kl-u-background-alt v-kl-u-box-padding>
                <vl-column width-l="12" v-if="noActiveRoundRunning && invoiceFormVisible">
                    <form class="kl-start-invoicing vl-form" @submit="onSubmit">
                        <vl-column width-s="12" width-m="8" width-l="12">
                            <strong>Te factureren organisaties:</strong>
                            <vl-u-spacer mod-small />
                        </vl-column>
                        <vl-column width-s="12" width-m="8" width-l="12">
                            <vl-radio id="organisation-all" name="organisation" :mod-disabled="isSending"
                                v-model="selectedOrganisationTpe" value="all" checked>Alle organisaties
                            </vl-radio>
                            <vl-radio id="organisation-select" name="organisation" :mod-disabled="isSending"
                                v-model="selectedOrganisationTpe" value="select">Selecteer de organisatie(s)
                            </vl-radio>
                            <vl-u-spacer />
                        </vl-column>
                        <vl-column width-s="12" width-m="12" width-l="12" v-if="organisationSelection">
                            <vl-multiselect v-model="selectedOrganisations" :id="uniqueFieldId('organisation')"
                                :mod-multiple="true" :show-no-results="false" :clear-on-select="true"
                                :hide-selected="true" placeholder="Zoek een organisatie" track-by="organisationId"
                                :custom-label="organisation => organisation.namePath" :options="organisationSuggestion"
                                @search-change="onOrganisationInputChange">
                                <template v-slot:noResult>
                                    <span>Geen resultaten gevonden...</span>
                                </template>
                                <template v-slot:noOptions>
                                    <span>Geen opties beschikbaar!</span>
                                </template>
                            </vl-multiselect>
                            <vl-u-spacer mod-small />
                        </vl-column>
                        <vl-column width="4">
                            <kl-field-validation-wrapper :field="dateFromField" #default="{hasErrors}" mod-required>
                                <kl-datepicker :mod-error="hasErrors" v-model="dateFrom" :max-date="dateFromMaxDate" />
                            </kl-field-validation-wrapper>
                            <vl-u-spacer />
                        </vl-column>
                        <vl-column>
                            <vl-checkbox v-model="overruleDryRun" ref="overruleCheckbox">
                                Toch doorgaan indien de dry run fouten bevat
                            </vl-checkbox>
                        </vl-column>
                        <vl-button type="submit" :mod-disabled="startingNewRound" mod-large>Start</vl-button>
                    </form>
                </vl-column>
                <template v-if="!startingNewRound">
                    <vl-column>
                        <div class="log">
                            <div v-for="log in currentInvoiceRound.log" :class="logClass(log)">
                                <span>{{formatDate(log.timestamp)}}</span> - <span v-html="log.message"></span>
                            </div>
                        </div>
                    </vl-column>
                </template>
            </vl-form-grid>
        </vl-column>
    </vl-form-grid>
</vl-region>
