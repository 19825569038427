import {defineComponent, ref, computed, watch, ComputedRef} from "vue";
import {Mri} from "@/app/mri/shared/services/services";
import DateUtil from "@/app/shared/helpers/date-util";
import { debounce, first } from "lodash";
import Guid from "@/app/shared/helpers/guid";

export default defineComponent({
    props: {
        debounceWait: {
            type: Number,
            default: 500
        }
    },
    setup(props, context) {
        const dateFrom = ref<Date[]>([DateUtil.now().add(-1, 'year').toDate()]);
        const dateTo = ref<Date[]>([DateUtil.now().toDate()]);

        const watchFields: ComputedRef<[Date[], Date[]]> = computed(() => {
            return [dateFrom.value, dateTo.value];
        });

        const dateFromMaxDate = computed(() => {
            return DateUtil.formatDate(first(dateTo.value));
        });

        const dateToMinDate = computed(() => {
            return DateUtil.formatDate(first(dateFrom.value));
        });

        const documentReference = ref<string>('');

        const debouncedSearch = debounce(() => {
            const emitSearch: Mri.IInvoicesSearchFields = {
                date: {
                    from: dateFrom.value && dateFrom.value[0] ? DateUtil.parseToDate(first(dateFrom.value)) : null,
                    to: dateTo.value && dateTo.value[0] ? DateUtil.parseToDate(first(dateTo.value)) : null
                },
                documentReference: documentReference.value
            };

            context.emit("search", emitSearch);
        }, props.debounceWait);

        const searchFieldChanged = () => {
            debouncedSearch();
        }

        const search = () => {
            const emitSearch: Mri.IInvoicesSearchFields = {
                date: {
                    from: DateUtil.parseToDate(first(dateFrom.value)),
                    to: DateUtil.parseToDate(first(dateTo.value)),
                },
                documentReference: documentReference.value
            };
            context.emit("search", emitSearch);
        };

        const uniqueFieldId = (id: string) => {
            return id + Guid.randomGuid();
        }

        watch(watchFields, (newSearchValue, oldSearchValue) => {
            if (JSON.stringify(newSearchValue) !== JSON.stringify(oldSearchValue)) {
                search();
            }
        }, { immediate: false, deep: true });

        watch(documentReference, debouncedSearch, {immediate: false, deep: true});

        return {
            search,
            dateFrom,
            dateFromMaxDate,
            dateTo,
            dateToMinDate,
            uniqueFieldId,
            documentReference,
            searchFieldChanged
        };
    },
});
