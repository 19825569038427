import { computed, defineComponent, ref, watch } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {escape} from "html-escaper";
import {Mri} from "@/app/mri/shared/services/services";
import {isEmpty} from "lodash-es";
import {useField, useForm} from "vee-validate";
import {klValidateRules} from "@/app/shared/components/kl-form-fields/kl-field-validation-util";
import {useKlModalEventsUtil} from "@/app/shared/helpers/kl-modal-events-util";

export default defineComponent({
    name: 'KlOrganisationActor',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object as () => KlipApi.OrganisatieAthumiData,
            required: false,
            default: () => ({}),
        },
        showEmail : {
            type: Boolean,
            default: true
        },
        hasAthumiId : {
            type: Boolean,
            default: true
        },
        kboNumber : {
            type: String,
            required: false,
        },
        routeAdminOrganisationName : {
            type: String,
            required: false,
        }
    },
    setup(props, { emit }) {

        const editActorModal = 'edit-actor-modal';

        const disableSubmit = computed((): boolean => !form.meta.value.valid);

        const isSending = ref<boolean>(false);

        const form = useForm();

        const noVatNumberPresent = ref<boolean>(true);

        const athumiOrganisationVat = ref<string>(null);

        const athumiOrganisation = ref<string>(null);

        const subOrgRouteName = ref<string>(null);

        const emailAddressField = useField<string>('E-mailadres', 'required|email');

        const vatNumberField = useField<string>(
            'BTW-nummer',
            (newValue, params) => klValidateRules({ required: true, max: 20, vatNumberValid: true }, newValue, params));

        const athumiOrganisationContactAddress = ref<string>(null);

        const actorAddress = ref<Mri.IAddress>(null);

        const initValue = ref<KlipApi.OrganisatieAthumiData>(null);

        const email = ref<string>(null);
        const vatNumber = ref<string>(null);

        const onAddressUpdate = (newAddress: Mri.IAddress) => {
            actorAddress.value = newAddress;
        }

        const onEmailUpdate = (newEmail: string) => {
            emailAddressField.setValue(newEmail, true);
            email.value = newEmail;
        }

        const onVatUpdate = (newVat: string) => {
            vatNumberField.setValue(newVat, true);
            vatNumber.value = newVat;
        }

        const editActor = () => {
            editActorModalEvents.triggerModalToggle();
            edit.value = true;
        }

        const saveActor = () => {

            form.validate();

            if (!form.meta.value.valid) {
                return;
            }
            isSending.value = true;
            const value = KlipApi.OrganisatieAthumiData.fromJS(initValue.value);

            value.straat = actorAddress.value.street;
            value.countryCode = actorAddress.value.countryCode;
            value.emailAdressen = [email.value];
            value.nummer = actorAddress.value.streetNumber;
            value.bus = actorAddress.value.box;
            value.gemeente = actorAddress.value.city;
            value.postcode = actorAddress.value.zip;
            value.vatNumber = vatNumber.value;

            emit('update:modelValue', value);

            _setActor(value);

            edit.value = false;
            editActorModalEvents.triggerModalToggle();
        }

        const cancelEdit = () => {
            isSending.value = false;
            edit.value = false;
        }

        const edit = ref<boolean>(false);

        const _setKboNumber = (kboNumber: string) => {
            if (!isEmpty(kboNumber)) {
                vatNumberField.setValue('BE' + kboNumber, true);
                vatNumber.value = 'BE' +  kboNumber;
            }
            noVatNumberPresent.value = isEmpty(kboNumber);
        }

        const _setActor = (newActor: KlipApi.OrganisatieAthumiData) => {
            if (!isEmpty(newActor) && newActor.hasValidAthumiId) {

                initValue.value = newActor;

                const athumiData = newActor;
                const street = `${athumiData.straat ? escape(athumiData.straat) : ''}${athumiData.straat && athumiData.nummer ? ` ${escape(athumiData.nummer)}` : ''}${athumiData.bus ? ` ${escape(athumiData.bus)}` : ''}<br>`;
                const city = `${athumiData.postcode && athumiData.gemeente ? `${escape(athumiData.postcode)} ` : ''}${athumiData.gemeente ? escape(athumiData.gemeente) : ''}`;

                athumiOrganisationContactAddress.value = `${street}${city}`;
                athumiOrganisation.value = `${athumiData.organisatieNaam} - ${athumiData.identificatieNummer}`;
                athumiOrganisationVat.value = athumiData.vatNumber;
                noVatNumberPresent.value = !athumiData.vatNumber;

                actorAddress.value = {
                    street: athumiData.straat,
                    streetNumber: athumiData.nummer,
                    box: athumiData.bus,
                    city: athumiData.gemeente,
                    zip: athumiData.postcode,
                    countryCode: athumiData.countryCode
                };

                email.value = athumiData.emailAdressen[0];
                emailAddressField.setValue(athumiData.emailAdressen[0], true);

                vatNumber.value = athumiData.vatNumber;
                vatNumberField.setValue(athumiData.vatNumber, true);
            }
            else
            {
                initValue.value = null;

                athumiOrganisationContactAddress.value = '';
                athumiOrganisation.value = '';
                athumiOrganisationVat.value = '';
                email.value = null;
                vatNumber.value = null;

                actorAddress.value = {
                    street: '',
                    streetNumber: '',
                    box: '',
                    city: '',
                    zip: '',
                    countryCode: '',
                };

                emailAddressField.setValue('', false);
                vatNumberField.setValue('', false);
            }
        }

        watch(
            () => props.modelValue,
            (val: KlipApi.OrganisatieAthumiData) => {
                _setActor(val);
            },
            { immediate: true, deep: true })

        watch(
            () => props.kboNumber,
            (val: string) => {
                _setKboNumber(val);
            },
            { immediate: true, deep: true })

        watch(
            () => props.routeAdminOrganisationName,
            (val: string) => {
                subOrgRouteName.value = val;
            },
            { immediate: true, deep: true })

        const editActorModalEvents = useKlModalEventsUtil(editActorModal, {
            onClosed() {
                isSending.value = false;
                edit.value = false;
            },
        });

        const onSubmit = form.handleSubmit(saveActor);

        return {
            noVatNumberPresent,
            onVatUpdate,
            vatNumber,
            emailAddressField,
            editActorModal,
            athumiOrganisationContactAddress,
            athumiOrganisationVat,
            subOrgRouteName,
            athumiOrganisation,
            editActor,
            cancelEdit,
            actorAddress,
            email,
            vatNumberField,
            onAddressUpdate,
            onEmailUpdate,
            onSubmit,
            edit,
            disableSubmit,
            isSending
        };
    }
});
