import {DirectiveBinding, watch} from 'vue';

export default {
    mounted: (el: HTMLElement, binding: DirectiveBinding) => {
        watch (() => binding.value, (newValue) => {
            if (newValue) {
                el.focus();
            }
            else {
                el.blur();
            }
        }, { immediate: true })
    }
};
