<form @submit.prevent="search" class="kl-search-invoices vl-form" v-kl-u-background-alt v-kl-u-box-padding>
    <div v-kl-u-background-white v-kl-u-box-padding>
        <vl-form-grid mod-stacked>
            <vl-column width="8">
                <label for="documentReference">Doc nr</label>
                <vl-input-field name="documentReference" placeholder="Zoek trefwoord" autocomplete="off"
                    v-model="documentReference" mod-block />
            </vl-column>
            <vl-column width="4">
                <vl-form-grid mod-stacked>
                    <vl-column width="6">
                        <label for="date-from">van</label>
                        <kl-datepicker v-model="dateFrom" :max-date="dateFromMaxDate" />
                    </vl-column>
                    <vl-column width="6">
                        <label for="date-to">tot en met</label>
                        <kl-datepicker v-model="dateTo" :min-date="dateToMinDate" />
                    </vl-column>
                </vl-form-grid>
            </vl-column>
            <vl-column v-vl-visually-hidden>
                <vl-action-group>
                    <vl-button type="submit" v-vl-visually-hidden>Zoeken</vl-button>
                </vl-action-group>
            </vl-column>
        </vl-form-grid>
    </div>
</form>
