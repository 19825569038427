import * as KlipApi from '@/api/klip-api.proxy';
import { IMapRequestListItem } from './shared/una-model';
import { useErrorStore } from '../../stores/error-store';
import {klipApiProxy} from '@/plugins/proxy-client';
import {defineStore} from 'pinia';
import {computed, ref, Ref} from 'vue';
import {handleAbortError, KlAbortController} from '@/app/shared/helpers/kl-abort-controller';
import DateUtil from "@/app/shared/helpers/date-util";

export interface IMapRequestArchive {
    id: string;
    zoneId: string;
    status: string;
}

export const useUnaStore = defineStore('una-store', () => {

    const _una_GetMapRequestsToConfirm_AbortController = new KlAbortController();
    const _una_GetMapRequestsToConfirmCount_AbortController = new KlAbortController();
    const _una_GetMapRequestsToReply_AbortController = new KlAbortController();
    const _una_GetMapRequestsToReplyCount_AbortController = new KlAbortController();

    const toConfirm: Ref<IMapRequestListItem[] | null> = ref(null);
    const toReply: Ref<IMapRequestListItem[] | null> = ref(null);
    const toReplyTotalCount: Ref<number | null> = ref(null);
    const toConfirmTotalCount: Ref<number | null> = ref(null);
    const items: Ref<IMapRequestArchive[]> = ref([]);

    const mapRequestsToConfirm = computed((): IMapRequestListItem[] => {
        return toConfirm.value;
    });

    const mapRequestsToConfirmTotalCount = computed((): number => {
        return toConfirmTotalCount.value;
    });

    const mapRequestsToReply = computed((): IMapRequestListItem[] => {
        return toReply.value;
    });

    const mapRequestsToReplyTotalCount = computed((): number => {
        return toReplyTotalCount.value;
    });

    const mapRequestArchives = computed((): IMapRequestArchive[] => {
        return items.value;
    });

    const fetchMapRequestToConfirm = async (input: KlipApi.GetMapRequestsToConfirmInput): Promise<void> => {
        const mapRequest = klipApiProxy.una_GetMapRequestsToConfirm(input, _una_GetMapRequestsToConfirm_AbortController.abortAndCreateNewSignal());
        const mapRequestTotalCount = klipApiProxy.una_GetMapRequestsToConfirmCount(input, _una_GetMapRequestsToConfirmCount_AbortController.abortAndCreateNewSignal());

        return Promise.all([mapRequest, mapRequestTotalCount])
            .then((responses) => {
                const result = {
                    mapRequests: responses[0].result.mapRequests,
                    totalCount: responses[1].result,
                };
                _mapRequestToConfirmMutation(result);
            })
            .catch(handleAbortError);
    }

    const fetchMapRequestToReply = async (input: KlipApi.GetMapRequestsToReplyInput): Promise<void> => {
        const mapRequest = klipApiProxy.una_GetMapRequestsToReply(input, _una_GetMapRequestsToReply_AbortController.abortAndCreateNewSignal());
        const mapCount = klipApiProxy.una_GetMapRequestsToReplyCount(input, _una_GetMapRequestsToReplyCount_AbortController.abortAndCreateNewSignal());

        return Promise.all([mapRequest, mapCount])
            .then((responses) => {
                const result = {
                    mapRequests: responses[0].result.mapRequests,
                    totalCount: responses[1].result,
                };
                _mapRequestToReplyMutation(result);
            })
            .catch(handleAbortError);
    }

    const confirmMapRequests = async (mapRequests: KlipApi.ConfirmMapRequestsInput[]): Promise<void> => {
        useErrorStore().setBypassError(['404']);
        await klipApiProxy.una_ConfirmMapRequests(mapRequests);
        _confirmMapRequestsMutation(mapRequests);
    }

    const notInvolved = async (input: KlipApi.MapRequestNotInvolvedInput): Promise<void> => {
        useErrorStore().setBypassError(['405']);
        await klipApiProxy.una_NotInvolved(input);
        _notInvolvedMutation(input);
    }


    const _mapRequestToConfirmMutation = (result: { mapRequests: KlipApi.MapRequestToConfirmListItem[]; totalCount: number; }) => {
        const toConFirmMapRequests = result.mapRequests.map((mapRequestResponse) => ({
            confirmationDate: mapRequestResponse.confirmationDate,
            dateReceived: DateUtil.formatDate(mapRequestResponse.dateReceived),
            isOverdue: mapRequestResponse.isOverdue,
            mapRequestId: mapRequestResponse.mapRequestId,
            reference: mapRequestResponse.reference,
            unaZoneId: mapRequestResponse.unaZoneId,
            zoneName: mapRequestResponse.zoneName,
            startDate: mapRequestResponse.startDate,
            mapRequestType: mapRequestResponse.mapRequestType,
            error: mapRequestResponse.isOverdue,
            organisation: mapRequestResponse.organisation,
            checked: true,
        }));
        toConfirm.value = toConFirmMapRequests;
        toConfirmTotalCount.value = result.totalCount;
    }

    const _mapRequestToReplyMutation = (result: { mapRequests: KlipApi.MapRequestToReplyListItem[]; totalCount: number; }) => {
        const mapRequests = result.mapRequests.map((mapRequestResponse) => ({
            isOverdue: false,
            dateReceived: DateUtil.formatDate(mapRequestResponse.dateReceived),
            mapRequestId: mapRequestResponse.mapRequestId,
            reference: mapRequestResponse.reference,
            unaZoneId: mapRequestResponse.unaZoneId,
            zoneName: mapRequestResponse.zoneName,
            startDate: DateUtil.formatDate(mapRequestResponse.startDate),
            mapRequestType: mapRequestResponse.mapRequestType,
            organisation: mapRequestResponse.organisation,
        }));
        toReply.value = mapRequests;
        toReplyTotalCount.value = result.totalCount;
    }

    const _notInvolvedMutation = (input: KlipApi.MapRequestNotInvolvedInput) => {
        if (toReply.value) {
            toReply.value = toReply.value
                .filter((item) => item.mapRequestId !== input.mapRequestId && item.unaZoneId !== input.zoneId);
            toReplyTotalCount.value = toReplyTotalCount.value - 1;
        }
    }

    const _confirmMapRequestsMutation = (confirmedMapRequests: KlipApi.ConfirmMapRequestsInput[]) => {
        if (!toConfirm.value) {
            return;
        }

        const confirmedRequests = toConfirm.value
            .filter((mapRequest) => {
                const index = confirmedMapRequests
                    .findIndex((x) => mapRequest.mapRequestId === x.mapRequestId && mapRequest.unaZoneId === x.zoneId);
                return index >= 0;
            });

        toConfirm.value = toConfirm.value
            .filter((mapRequest) => {
                const index = confirmedRequests
                    .findIndex((x) => mapRequest.mapRequestId === x.mapRequestId && mapRequest.unaZoneId === x.unaZoneId);
                return index < 0;
            });
        toConfirm.value.forEach((x) => x.checked = false);
        toConfirmTotalCount.value = toConfirmTotalCount.value - confirmedRequests.length;

        toReply.value = toReply.value.concat(confirmedRequests);
        toReplyTotalCount.value = toReplyTotalCount.value + confirmedRequests.length;
    }

    // ?
    const _mapRequestArchiveStatusMutation = (mapRequestArchive: IMapRequestArchive) => {
        if (items.value.filter((item) => item.id === mapRequestArchive.id && item.zoneId === mapRequestArchive.zoneId).length) {
            items.value = items.value.map((item) => (item.id === mapRequestArchive.id && item.zoneId === mapRequestArchive.zoneId) ? mapRequestArchive : item);
        } else {
            items.value.push(mapRequestArchive);
        }
    }

    return {
        mapRequestsToConfirm,
        mapRequestsToConfirmTotalCount,
        mapRequestsToReply,
        mapRequestsToReplyTotalCount,
        mapRequestArchives,

        fetchMapRequestToConfirm,
        fetchMapRequestToReply,
        confirmMapRequests,
        notInvolved,
    }
});
