import {defineComponent} from 'vue';
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    name: 'KlDatepicker',
    props: {
        modelValue: { type: Array as () => String[], required: false, default: undefined },
    },
    emits: ['update:modelValue'],
    compatConfig: {
        MODE: 3
    },
    setup(props, { emit }) {

        const onUpdate = (newValue: string[]) => {
            // KLIP specific consistent parsing/formatting
            const dateString = DateUtil.formatDate(DateUtil.parseToDayJs(newValue));
            emit('update:modelValue', [dateString]);
        }

        return {
            onUpdate,
        }
    }
});
