<div>
    <kl-description-list>
        <kl-description-list-row>
            <vl-u-spacer mod-small />
            <kl-contact-card :title="athumiOrganisation" :subtitle="athumiOrganisationVat" :address="athumiOrganisationContactAddress" />
            <template v-if="showEmail">
                <vl-link v-if="hasAthumiId" :href="`mailto:${email}`" icon="mail" mod-icon-before >{{ email }}</vl-link>
            </template>
            <template v-else>
                <p v-if="!!subOrgRouteName">E-mailadres(sen) voor organisaties op factuur, zie tabblad <kl-router-link :to="{ subOrgRouteName, hash: '#suborganisations'}" class="vl-link">Suborganisaties.</kl-router-link></p>
            </template>
            <vl-action-group>
                <vl-button tag-name="a" @click.prevent="editActor" class="vl-button vl-button--link">
                    <template v-if="hasAthumiId">Actor aanpassen</template>
                    <template v-else>Actor aanmaken</template>
                </vl-button>
            </vl-action-group>
        </kl-description-list-row>
    </kl-description-list>

    <vl-modal :id="editActorModal" :closable="true" mod-medium>
        <form class="vl-form" @submit="onSubmit">
            <vl-title tag-name="h3">Actor aanpassen</vl-title>
            <span ng-if="!!athumiOrganisation">{{athumiOrganisation}}</span>
            <vl-u-spacer mod-small />

            <vl-form-grid mod-stacked>
                <template v-if="noVatNumberPresent">
                    <vl-column>
                        <kl-field-validation-wrapper :field="vatNumberField" #default="{hasErrors}" mod-required>
                            <vl-input-field name="KBO-nummer" id="vatNumber" :model-value="vatNumber" @update:modelValue="onVatUpdate" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                </template>
            </vl-form-grid>

            <kl-address-form ref="addressForm" :model-value="actorAddress" @update:modelValue="onAddressUpdate" />

            <vl-u-spacer mod-small />

            <vl-form-grid mod-stacked>
                <template v-if="showEmail">
                    <vl-column>
                        <kl-field-validation-wrapper :field="emailAddressField" #default="{hasErrors}" mod-required>
                            <vl-input-field name="e-mailadres" id="email-address" :model-value="email" @update:modelValue="onEmailUpdate" autocomplete="on" placeholder="E-mail" :mod-error="hasErrors" mod-block />
                        </kl-field-validation-wrapper>
                    </vl-column>
                </template>
                <vl-action-group>
                    <vl-button type="submit" mod-large :mod-disabled="disableSubmit" :mod-loading="isSending">Wijzigingen opslaan</vl-button>
                    <vl-button class="vl-button--link" @click="cancelEdit"
                               v-vl-modal-toggle="'edit-actor-modal'" icon="cross" mod-icon-before>Annuleer</vl-button>
                </vl-action-group>
            </vl-form-grid>
        </form>
    </vl-modal>
</div>
