<div>
    <!-- loading -->
    <vl-region v-if="isLoading">
        <vl-layout>
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
            </div>
        </vl-layout>
    </vl-region>
    <!-- ./loading -->
    <vl-region v-else-if="organisation">
        <vl-layout>
            <vl-title tag-name="h2">{{ organisation.name }}</vl-title>
            <vl-u-spacer mod-medium />
<!--            <vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }">-->
                <form class="vl-form" @submit="onSubmit">
                    <vl-form-grid mod-stacked>
                        <vl-column width-s="12" width-m="8" width-l="6">
                            <kl-description-list>
                                <kl-description-list-row label="GUID:" v-if="organisation.organisationId">{{ organisation.organisationId }}</kl-description-list-row>
                                <kl-description-list-row label="Suborganisatie van:" v-if="organisation.parentId && parentOrganisationName">{{ parentOrganisationName }}</kl-description-list-row>
                                <kl-description-list-row label="Naam organisatie:" v-if="!isEditable">{{ organisation.name }}</kl-description-list-row>
                            </kl-description-list>
                        </vl-column>
                        <vl-column width-s="0" width-m="4" width-l="6" v-vl-visibility:hide.s></vl-column>
                        <vl-column width-s="12" width-m="8" width-l="6" v-if="isEditable">
                            <kl-field-validation-wrapper :field="organisationNameField" #default="{hasErrors}" mod-required>
                                <vl-input-field name="name" id="organisation-name" v-model="organisation.name" :mod-error="hasErrors" mod-block />
                            </kl-field-validation-wrapper>
                        </vl-column>
                    </vl-form-grid>
                    <vl-u-spacer mod-medium />
                    <vl-button v-if="isEditable" type="submit" :mod-disabled="disableSubmit" :mod-loading="isSending" mod-large>Bewaar veranderingen</vl-button>
                </form>
<!--            </vl-form-validation-observer>-->
            <template v-if="showInvoiceSettings && organisationInvoiceData">
                <vl-u-spacer mod-medium />
                <template v-if="!editOrganisationInvoiceData">
                    <kl-invoice-settings :invoice-data="organisationInvoiceData" :edit-mode="false" />
                    <vl-button class="vl-button--link" v-if="canEditInvoiceData" @click="toggleEditMode">
                        Wijzig facturatiegegevens
                    </vl-button>
                </template>
                <kl-invoice-settings-form
                    v-else
                    @update:modelValue="saveOrganisationInvoiceData"
                    @cancel="toggleEditMode"
                    :invoice-data="organisationInvoiceData"
                    :is-sending="isSending"
                />
            </template>
            <vl-u-spacer mod-medium />
            <template v-if="showUnaSettings">
                <kl-organisation-una-settings :modelValue="organisationUnaSettings" @update:modelValue="unaSettingsUpdated" />
                <vl-u-spacer mod-medium />
                <kl-organisation-una-zones :value="organisationUnaSettings" ref="organisationUnaZones" v-if="isUnaConfirmed" />
            </template>
        </vl-layout>
    </vl-region>
</div>
