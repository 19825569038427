import {defineComponent, ref, watch, onMounted, computed, useSlots, nextTick, ComputedRef} from "vue";
import Guid from "@/app/shared/helpers/guid";
import DateUtil from "@/app/shared/helpers/date-util";
import * as KlipApi from '@/api/klip-api.proxy';
import {debounce, first} from "lodash-es";
import OrganisationHelper from "@/app/shared/helpers/organisation-helper";
import {ILocationPickerGeometry} from '@/app/shared/components/kl-locationpicker/kl-locationpicker';

export class MapRequestSearchField {
    searchField: string;
    keyword: string;
    date: {
        from: Date;
        to: Date;
    };
    searchOption?: string;
    organisationId?: string;
    location?: any;
    archive?: boolean;
    tags?: string[];
}

export const SearchOptions = [
    'None',
    'All',
    'Organisation',
    'My',
];

export default defineComponent({
    name: 'KlSearchMaprequests',
    emits: ['search'],
    props: {
        defaultSearchOption: {
            type: String,
            default: null,
            required: false,
        },
        searchFields: {
            type: Array<ICodeListValueOrder>,
            required: true,
        },
        searchOptions: {
            type: Array<ICodeListValueOrder>,
            default: null,
            required: false,
        },
        groupedSearchOptions: {
            type: Array<ICodeListValueOrder>,
            default: null,
            required: false,
        },
        tags: {
            type: Array<string>,
            default: null,
            required: false,
        },
        defaultFilter: {
            type: Object as () => MapRequestSearchField,
            default: null,
            required: false,
        },
        modSimple: {
            type: Boolean,
            required: false,
            default: false
        },
        onlyActive: {
            type: Boolean,
            required: false,
            default: false
        },
        debounceWait: {
            type: Number,
            default: 500
        }
    },
    setup(props, {emit}) {
        const guidValidationRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        const keyword = ref<string>('');
        const searchField = ref<string>(KlipApi.SearchField[2]);
        const searchOption = ref<string>(null);
        const searchArchive = ref<string>('false');
        const location = ref<ILocationPickerGeometry>(null);
        const locationpicker = ref();

        const tagsChecked = ref<boolean>(false);
        const searchTags = ref<string[]>([]);
        const dateFrom = ref<Date[]>([]);
        const dateTo = ref<Date[]>([]);
        const searchArchiveOptions = [
            {
                key: 'false',
                label: 'Actieve planaanvragen (< 6 maanden)',
            }, {
                key: 'true',
                label: 'Gearchiveerde planaanvragen (> 6 maanden)',
            },
        ];


        const hasTags = computed(() => {
            return props.tags && props.tags.length >= 0;
        });

        const tagsCheckboxEnabled = computed(() => {
            return searchArchive.value === 'false';
        });

        const tagsActive = computed(() => {
            return tagsChecked.value && tagsCheckboxEnabled.value;
        });

        const keyWordSearchWidth = computed(() => {
            return props.searchFields && props.searchFields.length <= 3 ? "4" : "3"
        });

        const dateFromMaxDate = computed(() => {
            return first(dateTo.value); // dateTo.value && dateTo.value.length > 0 ? dayjs(dateTo.value[0]).format('DD-MM-YYYY') : '';
        });

        const dateToMinDate = computed(() => {
            return first(dateFrom.value); // dateFrom.value && dateFrom.value.length > 0 ? dayjs(dateFrom.value[0]).format('DD-MM-YYYY') : '';
        });

        const watchFields: ComputedRef<[string, Date[], Date[],string, any, string, boolean ]> = computed(() => {
            return [
                keyword.value,
                dateFrom.value,
                dateTo.value,
                searchOption.value,
                location.value,
                searchArchive.value,
                tagsChecked.value,
            ];
        });

        const defaultSearchField = () => {
            return KlipApi.SearchField[2];
        };

        const uniqueFieldId = (id: string) => {
            return id + Guid.randomGuid();
        };

        const setDepth = (depth: number, name: string) => {
          return OrganisationHelper.setDepth(depth, name);
        }

        const clickTags = () => {
            tagsChecked.value = true;
            nextTick(() => {
                const searchTagsField = document.getElementsByName("searchTags");
                if (searchTagsField && searchTagsField.length) {
                    searchTagsField[0].focus();
                }
            });
        }

        const debouncedSearch = debounce(() => {
            const searchOptionIsGuid = guidValidationRegex.test(searchOption.value);
            const searchData: MapRequestSearchField = {
                searchField: searchField.value as keyof typeof KlipApi.SearchField,
                keyword: keyword.value,
                date: {
                    from: first(dateFrom.value),
                    to: first(dateTo.value),
                },
                searchOption: searchOption.value,
                organisationId: searchOptionIsGuid ? searchOption.value : null,
                location: location.value,
                archive: searchArchive.value === 'true',
                tags: tagsActive.value ? searchTags.value : null,
            }
            emit('search', searchData);
        }, props.debounceWait);

        const search = () => {
            debouncedSearch()
        };

        const searchFieldChanged = () => {
            if (keyword.value) {
                search();
            }
        };
        const tagsChanged = () => {
            search();
        };

        const reset = () => {
            if (locationpicker.value) {
                location.value = null;
                locationpicker.value.removeLocation();
            }
            keyword.value = '';
            searchField.value = defaultSearchField();
            dateFrom.value = null;
            dateTo.value = null;
            location.value = null;
            if (props.searchOptions) {
                searchArchive.value = 'false';
                searchOption.value = props.defaultSearchOption;
            }
            if (hasTags.value) {
                tagsChecked.value = false;
                searchTags.value = [];
            }
        }

        const defaultFilterChanged = (filter: MapRequestSearchField) => {
            if (filter == null) {
                return;
            }

            if (filter.date && filter.date.from) {
                dateFrom.value = [filter.date.from];
            }
            if (filter.date && filter.date.to) {
                dateTo.value = [filter.date.to];
            }
            if(filter.keyword) {
                keyword.value = filter.keyword;
            }
            if(filter.location) {
                location.value = filter.location;
            }
            if(filter.tags) {
                searchTags.value = filter.tags;
                tagsChecked.value = filter.tags && filter.tags.length > 0;
            }
            if(filter.searchField) {
                searchField.value = filter.searchField;
            }
            if(filter.archive){
                searchArchive.value = String(filter.archive);
            }
        };

        watch(
            watchFields,
            (val, oldVal) => {
                if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
                    search();
                }
            },
            { immediate: false, deep: true });

        watch(
            () => props.defaultFilter,
            (val, oldVal) => {
                if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
                    defaultFilterChanged(val);
                }
            },
            { immediate: true, deep: true });

        onMounted(() => {
            searchOption.value = props.defaultSearchOption;
            defaultFilterChanged(props.defaultFilter);
        });

        return {
            keyword,
            searchField,
            searchOption,
            searchArchive,
            location,
            tagsChecked,
            searchTags,
            dateFrom,
            dateTo,
            hasTags,
            tagsActive,
            locationpicker,
            keyWordSearchWidth,
            uniqueFieldId,
            searchArchiveOptions,
            tagsCheckboxEnabled,
            dateFromMaxDate,
            dateToMinDate,
            watchFields,
            setDepth,
            clickTags,
            search,
            reset,
            searchFieldChanged,
            tagsChanged
        };
    }
});
