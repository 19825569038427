import dayjs from 'dayjs';
import { useCodeListStore } from '@/app/shared/state/CodeListModule';

export default class DateUtil {
    private static dateFormat: string = 'DD/MM/YYYY';
    private static timeFormat: string = 'HHumm';
    private static dateTimeFormat: string = 'DD/MM/YYYY - HHumm';

    public static now(): dayjs.Dayjs {
        return dayjs();
    }

    public static today(): dayjs.Dayjs {
        return DateUtil.now().startOf('day');
    }

    public static formatDate(date: any): string {
        if (!date || (date.toString() === 'Invalid Date')) {
            return '';
        }
        return DateUtil._safeParse(date).format(DateUtil.dateFormat);
    }

    public static formatTime(date: any): string {
        if (!date || (date.toString() === 'Invalid Date')) {
            return '';
        }
        return DateUtil._safeParse(date).format(DateUtil.timeFormat);
    }

    public static formatDateTime(date: any): string {
        if (!date || (date.toString() === 'Invalid Date')) {
            return '';
        }
        return DateUtil._safeParse(date).format(DateUtil.dateTimeFormat);
    }

    /*
     * legacyFormat = informatief (mag weg als alle testen ok zijn)
     */
    public static formatForApi(date: any, legacyFormat: string): string {
        // console.log(`formatForApi(${date}, [legacy] ${legacyFormat})`);

        if (!date || (date.toString() === 'Invalid Date')) {
            return undefined;
        }
        return DateUtil._safeParse(date).toISOString();
    }

    private static _safeParse(date: any): dayjs.Dayjs {
        if (typeof date !== 'string') {
            // assume Date or dayjs.DayJs
            return dayjs(date);
        }

        // TODO: betere check?
        if (date.includes('GMT')) {
            return dayjs(date);
        }

        const parsedDate = dayjs(date, 'DD/MM/YYYY', true);
        if (parsedDate.isValid()) {
            return parsedDate;
        }

        return dayjs(date);
    }

    /*
     * Input can be: string, string[], Date, Date[], DayJs, DayJs[], ..
     */
    public static parseToDayJs(input: any): dayjs.Dayjs {
        if (!input) {
            return undefined;
        }
        const date = Array.isArray(input) ? input[0] : input;
        return DateUtil._safeParse(date);
    }

    public static parseToDate(input: any): Date {
        if (!input) {
            return undefined;
        }
        const date = Array.isArray(input) ? input[0] : input;
        return DateUtil._safeParse(date).toDate();
    }

    public static periodStringFromDates(startDate: string, endDate: string): string {
        return `${startDate ? `van ${DateUtil.formatDate(startDate)}` : ''}
                ${startDate && endDate ? ' ' : ''}
                ${endDate ? `tot ${DateUtil.formatDate(endDate)}` : ''}`;
    }

    public static getWorkingDayByCountAndHolidays(startDate: dayjs.Dayjs, count: number) {
        if (useCodeListStore().holidays) {
            let businessDaysCount = 0;
            const holidays = useCodeListStore().holidays.map((holiday) => {
                return dayjs(holiday).format(DateUtil.dateFormat);
            });

            let date = startDate.clone();

            const holidaysList: string[] = [];

            while (businessDaysCount < count) {
                if (DateUtil.isBusinessDay(date, holidays)) {
                    businessDaysCount++;
                }
                if (DateUtil.isHolidayAndNotWeekendDay(date, holidays)) {
                    holidaysList.push(date.locale('nl-be').format('D MMMM'));
                }
                date = date.add(1, 'day');
            }

            return {
                workingDay: date,
                holidays: holidaysList,
             };
        }
        return null;
    }

    public static businessDaysBetween(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
        if (useCodeListStore()) {
            const holidays = useCodeListStore().holidays.map((holiday) => {
                return dayjs(holiday).format(DateUtil.dateFormat);
            });
            return DateUtil.businessAndHolidaysBetween(endDate, startDate, holidays);
        }
    }

    private static businessAndHolidaysBetween(endDate: dayjs.Dayjs, startDate: dayjs.Dayjs, holidays: string[]) {
        const clonedEndDate = endDate.clone();
        const clonedStartDate = startDate.clone();

        let start = clonedEndDate < clonedStartDate ? clonedEndDate : clonedStartDate;
        const end = clonedStartDate > clonedEndDate ? clonedStartDate : clonedEndDate;

        let daysBetween = 0;

        if (start.format(DateUtil.dateFormat) === end.format(DateUtil.dateFormat)) {
            return daysBetween;
        }

        while (start < end) {
            if (DateUtil.isBusinessDay(start, holidays)) {
                daysBetween++;
            }
            start = start.add(1, 'day');
        }

        return daysBetween;
    }

    private static isBusinessDay(date: dayjs.Dayjs, holidays: string[]) {
        const defaultWorkingWeekdays = [1, 2, 3, 4, 5];
        const isHoliday = holidays.indexOf(date.format(DateUtil.dateFormat)) >= 0;

        if (isHoliday) {
            return false;
        }

        return defaultWorkingWeekdays.indexOf(date.day()) >= 0;
    }

    private static isHolidayAndNotWeekendDay(date: dayjs.Dayjs, holidays: string[]) {
        const defaultWeekendDays = [0, 6];

        const isWeekendDay = defaultWeekendDays.indexOf(date.day()) >= 0;

        if (isWeekendDay) {
            return false;
        }

        return holidays.indexOf(date.format(DateUtil.dateFormat)) >= 0;
    }
}
