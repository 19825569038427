import {App} from 'vue';
import VueGtag from 'vue-gtag';

export default {
    install(app: App, options) {
        app.use(VueGtag, {
            config: {
                id: import.meta.env.VITE_GA4,
                params: {
                    send_page_view: false, // no pageview on initial load!
                    anonymize_ip: true,
                    allow_google_signals: false, // disable all advertising features
                    debug_mode: !!import.meta.env.VITE_GA4_DEBUG_MODE
                }
            },
        }, options.router);
    }
}
