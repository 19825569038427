<div>
    <vl-title tag-name="h2"  v-if="isUna || shouldConfirmUnaCreation">Kabel- en leidingbeheerder</vl-title>
    <vl-u-spacer mod-medium />
    <template v-if="shouldConfirmUnaCreation">
        <vl-typography>
            <p>
                Beheert uw organisatie ondergrondse kabels en/of leidingen op het grondgebied van het Vlaamse Gewest, al dan niet op privéterrein?
                Dan bent u verplicht om u als kabel- en leidingbeheerder (KLB) te registreren in KLIP.
                Opgelet: registreer u pas effectief wanneer u ook al in staat bent om uw leidinggegevens op de correcte wijze door te geven. Eenmaal u planaanvragen ontvangt, moet u deze binnen de 5 werkdagen beantwoorden.
            </p>
            <vl-title tag-name="h5">1. Plandata in IMKL-formaat voorbereiden</vl-title>
            <p>
                IMKL is het datamodel om plandata uit te wisselen met KLIP en is een uitbreiding op de Europese uitwisselingsstandaard voor kabel- en leidinginformatie INSPIRE Utility Services, afgekort ‘INSPIRE US’.
                Lees meer over het IMKL-formaat en hoe u uw leidinggegevens kan omzetten naar IMKL.
            </p>
            <vl-title tag-name="h5">2. KLB-zone opladen</vl-title>
            <p>Eens alle plandata in IMKL formaat beschikbaar is, kan de zone waar u kabels en/of leidingen in beheer hebt geregistreerd worden in KLIP.</p>
            <vl-title tag-name="h5">3. Aanvragen ontvangen</vl-title>
            <p>Vanaf de KLB-zone actief is, ontvangt u KLIP-aanvragen van personen die in uw zone graafwerken willen uitvoeren.</p>
            <vl-title tag-name="h5">4. Ontvangst bevestigen</vl-title>
            <p>U bevestigt de ontvangst van de planaanvragen die u kreeg.</p>
            <vl-title tag-name="h5">5. Planaanvraag beantwoorden</vl-title>
            <p>Hebt u leidingen in de planaanvraagzone? Dan moet u de aanvraag beantwoorden met digitale plannen in IMKL-formaat van uw ondergrondse infrastructuur. Beheert u geen kabels of leidingen in de aangevraagde zone, antwoord dan dat u toch geen betrokken partij bent.</p>
            <vl-title tag-name="h5">6. Resultaat</vl-title>
            <p>KLIP verwerkt uw antwoord in één overzichtelijk digitaal plan met de antwoorden van alle KLB’s.</p>
        </vl-typography>
        <vl-u-spacer mod-medium />
        <vl-button @click="confirmUnaCreation" :mod-loading="isSending" mod-large>Ja, ik ben een leidingbeheerder</vl-button>
    </template>
    <template v-else>
        <form class="vl-form" @submit="onSubmit" v-if="isUna">
            <vl-form-grid mod-stacked>
                <vl-column width-s="12" width-m="8" width-l="4">
                    <kl-field-validation-wrapper :field="displayNameField" #default="{hasErrors}" mod-required>
                        <vl-input-field name="Naam organisatie" v-model="displayName" :mod-disabled="!editable" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-s="0" width-m="4" width-l="8" v-vl-visibility:hide.s></vl-column>
                <vl-column width-s="12" width-m="8" width-l="4">
                    <kl-field-validation-wrapper :field="subjectSigningCertificateField" #default="{hasErrors}" :annotation="sscAnnotation">
                        <vl-input-field name="ssc" v-model="subjectSigningCertificate" :mod-disabled="!editable" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
            </vl-form-grid>
            <vl-u-spacer mod-medium />
            <vl-button type="submit" :mod-disabled="disableSubmit" :mod-loading="isSending" mod-large>Bewaar veranderingen</vl-button>
        </form>
    </template>
</div>
