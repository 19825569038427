import {
    IOrganisationUnaSettings,
    IOrganisationUnaSettingsInput,
    OrganisationUnaSettings,
    OrganisationUnaSettingsInput
} from '@/api/klip-api.proxy';
import {useField, useForm} from 'vee-validate';
import {EEventType, useSignalrHub} from '@/plugins/signalr';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import {computed, defineComponent, onBeforeUnmount, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {klValidateRules} from '@/app/shared/components/kl-form-fields/kl-field-validation-util';

export default defineComponent({
    name: 'KlOrganisationUnaSettings',
    compatConfig: {
        MODE: 3,
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object as () => IOrganisationUnaSettings,
            default: () => ({}),
            required: true,
        }
    },
    setup(props, { emit }) {

        const organisationNameValidationRegex = /^[a-zA-Z0-9\-_ .+!()&ö/:,è|'@\s]*$/;
        const sscAnnotationLink: string = import.meta.env.VITE_LINKS_IMKL_SIGNING_HELP;
        const sscAnnotation: string = `Enkel relevant om <a href="${sscAnnotationLink}" target="_blank" class="vl-link">IMKL antwoorden digitaal te ondertekenen</a>`;
        const imklInfoUrl: string = import.meta.env.VITE_LINKS_IMKLINFO;

        const initialSettings = ref<IOrganisationUnaSettings>(null);

        const form = useForm();
        const disableSubmit = computed((): boolean => {
            return !form.meta.value.valid || !form.meta.value.dirty;
        });

        const displayNameField = useField<string>(
            'Naam als leidingbeheerder in KLIP',
            (newValue, params) => klValidateRules({
                'required': true,
                'max': 90,
                'regex': organisationNameValidationRegex,
                'displayNameIsUnique': [initialSettings?.value.displayName]
            }, newValue, params))
        const subjectSigningCertificateField = useField<string>('Subject signing certificaat');

        const displayName = displayNameField.value;
        const subjectSigningCertificate = subjectSigningCertificateField.value;


        const isSending = ref<boolean>(false);
        const shouldConfirmUnaCreation = ref<boolean>(false);

        const isUna = computed(() => {
            return props.modelValue?.isUna;
        });

        const editable = computed(() => {
            return useUserStore().hasRole([EUserRole.una_manager, EUserRole.klim_admin]);
        });


        const confirmUnaCreation = () => {
            _saveUnaSettings();
        }


        const _saveUnaSettings = () => {
            if (shouldConfirmUnaCreation.value) {
                useSignalrHub().subToEvent(EEventType.UtilityNetworkAuthorityRegisteredEvent, _unaCreated);
                useSignalrHub().subscribeToOrganisationNotifications(props.modelValue.organisationId);
            }

            isSending.value = true;

            const organisationUnaSettingsInput: IOrganisationUnaSettingsInput = {
                organisationId: props.modelValue.organisationId,
                displayName: displayName.value,
                subjectSigningCertificate: subjectSigningCertificate.value,
            }

            useKlipApiProxy().organisation_EditUnaSettingsForOrganisation(OrganisationUnaSettingsInput.fromJS(organisationUnaSettingsInput))
                .finally(() => {
                    // wait emitting event when new una
                    if (shouldConfirmUnaCreation.value) {
                        return;
                    }

                    _emitUpdate();
                });
        }

        const _unaCreated = (message: IMessage = null) => {
            if (message && message.info === props.modelValue.organisationId) {
                _emitUpdate();
            }
        }

        const _emitUpdate = () => {
            isSending.value = false;
            shouldConfirmUnaCreation.value = false;

            const organisationUnaSettings: IOrganisationUnaSettings = {
                organisationId: props.modelValue.organisationId,
                isUna: props.modelValue.isUna,

                displayName: displayName.value,
                subjectSigningCertificate: subjectSigningCertificate.value,
            }

            emit('update:modelValue', organisationUnaSettings);

            form.resetForm({ values: form.values });
        }


        onBeforeUnmount(() => {
            useSignalrHub().unsubFromEvent(EEventType.UtilityNetworkAuthorityRegisteredEvent);
            useSignalrHub().unSubscribeFromOrganisationNotifications(props.modelValue.organisationId);
        });

        watch(
            () => props.modelValue,
            (newValue: IOrganisationUnaSettings) => {
                initialSettings.value = {
                    ...newValue,
                };

                displayName.value = newValue?.displayName;
                subjectSigningCertificate.value = newValue?.subjectSigningCertificate;

                form.resetForm({ values: form.values });
            },
            { immediate: true, deep: true }
        )


        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_saveUnaSettings, _onInvalidSubmit);

        return {
            displayName,
            subjectSigningCertificate,

            displayNameField,
            subjectSigningCertificateField,

            sscAnnotation,
            imklInfoUrl,

            isSending,
            shouldConfirmUnaCreation,
            isUna,

            editable,

            confirmUnaCreation,

            onSubmit,
            disableSubmit,
        }
    }
})
