import { computed, defineComponent, inject, onMounted, ref, watch, } from 'vue';
import * as KlipApi from '@/api/klip-api.proxy';
import KlSearchMaprequests, { MapRequestSearchField } from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests';
import { useRoute, useRouter } from '@/router/router-vue3';
import { AdminArchivedMapRequestDetail, AdminMapRequestDetail } from '@/app/admin/admin-routes';
import QueryFilter from "@/app/shared/helpers/queryFilter";
import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import { isEmpty } from "lodash-es";
import { EAlertType } from '@/app/shared/state/AlertModule';
import { MoveMapRequestsInput } from "@/api/klip-api.proxy";
import { IMapRequestToMove } from '@/app/admin/components/kl-move-map-requests/kl-move-map-requests';
import { IpproDatatable } from "@/app/shared/components/ippro-datatable/ippro-datatable-declare";
import {IAlertHandler, useAlertHandler} from '@/plugins/alert';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {handleAbortError, KlAbortController} from '@/app/shared/helpers/kl-abort-controller';
import DateUtil from '@/app/shared/helpers/date-util';

export interface IAdminUserMapRequestListItem {
    id?: string;
    reference?: string | undefined;
    requester?: string | undefined;
    organisation?: string | undefined;
    dateReceived?: string;
    status?: string | undefined;
    isMoving?: boolean;
    type?: string | undefined;
}

export default defineComponent({
    props: {
        userId: {
            type: String,
            required: true
        },
        organisationId: {
            type: String,
            default: null,
            required: true
        }
    },
    setup(props) {
        const proxy = useKlipApiProxy();
        const alertHandler = useAlertHandler();

        const _operations_GetMapRequests_AbortController = new KlAbortController();
        const _operations_GetMapRequestsCount_AbortController = new KlAbortController();

        const router = useRouter();
        const route = useRoute();

        const queryFilter = new QueryFilter(router);
        let initialMappingFromQuery = true;

        const viewModelLoading = ref<boolean>(false);
        const searchMaprequests = ref<InstanceType<typeof KlSearchMaprequests>>(null);
        const moveMapRequestChecked = ref<boolean>(false);
        const moveMapRequests = ref<boolean>(false);
        const mapRequestsColumns = ref<KlipApi.IColumn[]>([]);
        const mapRequestsMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 0, resultsPerPage: 50, currentPage: 1 });
        const mapRequestsFetching = ref<boolean>(true);
        const mapRequestsSearchFields = ref<KlipApi.ICodeListValueOrder[]>();
        const mapRequestsSearchOptions = ref<KlipApi.ICodeListValueOrder[]>();
        const mapRequestDefaultSearchOption = ref('allstatuses');
        const mapRequests = ref<IAdminUserMapRequestListItem[]>([]);
        const checkedMapRequests = ref<IAdminUserMapRequestListItem[]>([]);
        const mapRequestsInput = ref<KlipApi.IGetMapRequestsInput2>();
        const archivedSelected = ref<boolean>(false);
        const defaultFilter = ref<MapRequestSearchField>(new MapRequestSearchField());
        let oldMapRequestInput: string;

        const cellParser = (row: IpproDatatable.IRow, column: IpproDatatable.IColumn) => {
            const routeParams = {
                id: row.id,
            };
            switch (column.key) {
                case 'dateReceived':
                    return {
                        template: `<div>${DateUtil.formatDateTime(row[column.key])}</div>`,
                    };
                case 'id':
                    const id = row[column.key];
                    const routerLink = archivedSelected.value ?
                        `<kl-router-link :to='${JSON.stringify(AdminArchivedMapRequestDetail)}' :params='${JSON.stringify(routeParams)}'>${id}</kl-router-link>` :
                        `<kl-router-link :to='${JSON.stringify(AdminMapRequestDetail)}' :params='${JSON.stringify(routeParams)}'>${id}</kl-router-link>`;
                    return {
                        template: routerLink
                    };
                case 'organisation':
                    let organisation = row[column.key];

                    if (organisation === null) {
                        organisation = '';
                    }

                    return {
                        template: `<div>${organisation}</div>`
                    };
                default:
                    return {
                        template: `<div>${row[column.key]}</div>`
                    };
            }
        };

        const isAdmin = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.admin]);
        })

        const search = (searchFields: MapRequestSearchField) => {
            if (!initialMappingFromQuery) {
                mapRequestsFetching.value = true;
                mapRequestsInput.value = {
                    klipContactId: props.userId,
                    parentKlipOrganisationId: props.organisationId,
                    isCitizen: props.organisationId === null,
                    offset: 0,
                    limit: mapRequestsMeta.value.resultsPerPage,
                    orderDirection: KlipApi.OrderDirection.Asc,
                    query: searchFields.keyword,
                    searchField: KlipApi.SearchField[searchFields.searchField as keyof typeof KlipApi.SearchField],
                    mapRequestStatus: searchFields.searchOption !== 'allstatuses' ? searchFields.searchOption : '',
                    isArchived: searchFields.archive,
                    startDate: DateUtil.formatForApi(searchFields.date.from, 'ISO'),
                    endDate: DateUtil.formatForApi(searchFields.date.to, 'ISO'),
                    zoneGeometry: searchFields.location ? JSON.stringify(searchFields.location) : null,
                };
                archivedSelected.value = searchFields.archive;
                mapRequestsMeta.value.currentPage = 1;
                queryFilter.mapQuery(route, mapRequestsInput.value);
            }
            initialMappingFromQuery = false;
        };

        const onMoveMapRequests = (moveMapRequest: IMapRequestToMove) => {
            const request = new MoveMapRequestsInput();
            request.mapRequestIds = checkedMapRequests.value.filter(c => !c.isMoving).map(c => c.id);
            request.destinationKlipOrganisationId = moveMapRequest.destinationKlipOrganisationId;
            request.reason = moveMapRequest.reason;
            request.isArchived = archivedSelected.value;
            request.query = KlipApi.GetMapRequestsInput2.fromJS(mapRequestsInput.value);

            proxy.operations_MoveMapRequests(request).then(() => {
                //if the mapRequestIds is empty, the users has selected move all.
                if (isEmpty(request.mapRequestIds)) {
                    mapRequests.value.forEach((m) => {
                        m.isMoving = true;
                        m.type = 'processing'
                    });
                    showSuccess(mapRequestsMeta.value.totalRows);
                } else {
                    request.mapRequestIds.forEach((id) => {
                        mapRequests.value.find(m => m.id == id).isMoving = true;
                        mapRequests.value.find(m => m.id == id).type = 'processing';
                    });
                    showSuccess(request.mapRequestIds.length);
                }
            }).catch(() => {
                showFailure();
            });
        };

        const checkboxChanged = (checkedRows: IAdminUserMapRequestListItem[]) => {
            checkedMapRequests.value = checkedRows;
        };

        const hasCheckedMapRequest = computed(() => {
            return isEmpty(checkedMapRequests.value);
        })

        const moveMapRequestsChanged = (checked: boolean) => {
            moveMapRequests.value = checked;
            moveMapRequestChecked.value = checked;
        }

        const _fetchMapRequestsCount = async () => {
            try {
                const input = KlipApi.GetMapRequestsInput2.fromJS(mapRequestsInput.value);
                const response = await proxy.operations_GetMapRequestsCount(input, _operations_GetMapRequestsCount_AbortController.abortAndCreateNewSignal());
                mapRequestsMeta.value.totalRows = response.result;
            }
            catch (err) {
                handleAbortError(err);
            }
        };

        const _fetchMapRequests = async () => {
            try {
                const input = KlipApi.GetMapRequestsInput2.fromJS(mapRequestsInput.value);
                const response = await proxy.operations_GetMapRequests(input, _operations_GetMapRequests_AbortController.abortAndCreateNewSignal());
                mapRequests.value = response.result.map((x) => {
                    return {
                        ...x, type: x.isMoving ? 'processing' : ''
                    };
                });
            }
            catch (err) {
                handleAbortError(err);
            }
        };

        const onMapRequestsInputChanged = async (mapRequestInput: KlipApi.IGetMapRequestsInput2) => {
            if (!mapRequestInput) {
                return;
            }

            const mapRequestInputJson = JSON.stringify(mapRequestInput);
            if (mapRequestInputJson === oldMapRequestInput) {
                return;
            }

            oldMapRequestInput = mapRequestInputJson;
            mapRequestsFetching.value = true;
            await _fetchMapRequestsCount();
            await _fetchMapRequests();
            mapRequestsFetching.value = false;
        };

        const clearSearch = () => {
            searchMaprequests.value.reset();
        };

        const pagerClicked = (page: number) => {
            mapRequestsInput.value.offset = mapRequestsMeta.value.resultsPerPage * (page - 1);
            queryFilter.mapQuery(route, mapRequestsInput.value);
        }

        const columnClicked = (column: KlipApi.IColumn) => {
            mapRequestsInput.value.offset = 0;
            mapRequestsInput.value.orderBy = column.key.toLowerCase();
            mapRequestsInput.value.orderDirection = column.direction;
            queryFilter.mapQuery(route, mapRequestsInput.value);
        };

        const rowClicked = async (row: IpproDatatable.IRow) => {
            if (archivedSelected.value) {
                await router.push({name: AdminArchivedMapRequestDetail.name, params: {id: row.id}});
            } else {
                await router.push({name: AdminMapRequestDetail.name, params: {id: row.id}});
            }

        }

        const showSuccess = (numberOfMovedMapRequests: number) => {
            const toasterId = `confirm-event-replay-toaster-${Math.random()
                .toString(36)
                .substring(7)}`;
            const message =
                numberOfMovedMapRequests > 1
                    ? `Er zijn ${numberOfMovedMapRequests} planaanvragen in de queue gezet om te verplaatsen. U ontvangt een e-mail met het resultaat.`
                    : `Er is 1 planaanvraag in de queue gezet om te verplaatsen. U ontvangt een e-mail met het resultaat.`;
            alertHandler.addAlert({
                type: EAlertType.Success,
                id: toasterId,
                title: 'Planaanvragen verplaatsen',
                message: message,
                icon: 'info-circle',
                autoClose: 5000,
                closable: true,
            });
        };

        const showFailure = () => {
            const toasterId = `confirm-event-replay-failed-toaster-${Math.random()
                .toString(36)
                .substring(7)}`;
            const message =
                'Er ging iets mis! Uw planaanvragen zijn NIET in de queue gezet om te verplaatsen. Probeer je even opnieuw?';
            alertHandler.addAlert({
                type: EAlertType.Error,
                id: toasterId,
                title: 'Planaanvragen verplaatsen mislukt',
                message: message,
                icon: 'info-circle',
                autoClose: 5000,
                closable: true,
            });
        };

        watch(mapRequestsInput, onMapRequestsInputChanged, { immediate: true, deep: true });

        const _fetchSearchOprations = async () => {
            const response = await proxy.operations_SearchOperationsMapRequestForUserView();

            const queryTypeDefinition = {
                orderField: KlipApi.OrderField,
                orderDirection: KlipApi.OrderDirection,
                searchField: KlipApi.SearchField,
                mapRequestStatus: 'string',
                isArchived: 'boolean',
                startDate: 'Date',
                endDate: 'Date',
            };

            mapRequestsInput.value = queryFilter.mapFilterFromQuery(route, response.result.defaultSearchFilter, { searchField: 1 }, queryTypeDefinition);
            mapRequestsInput.value.klipContactId = props.userId;
            mapRequestsInput.value.parentKlipOrganisationId = props.organisationId;
            mapRequestsInput.value.isCitizen = props.organisationId === null;

            mapRequestsColumns.value = response.result.mapRequestColumns.map((column) => {
                return {
                    ...column, parser: cellParser,
                };
            });

            queryFilter.mapColumnsFromQuery(route, mapRequestsColumns.value, KlipApi.OrderField);

            mapRequestsSearchFields.value = response.result.searchFields;
            mapRequestsSearchOptions.value = response.result.searchOptions;
            if (mapRequestsInput.value.mapRequestStatus) {
                mapRequestDefaultSearchOption.value = mapRequestsInput.value.mapRequestStatus;
            }

            if (route.query.offset && + route.query.offset % mapRequestsMeta.value.resultsPerPage === 0) {
                mapRequestsMeta.value.currentPage = Math.ceil(+route.query.offset / mapRequestsMeta.value.resultsPerPage) + 1;
            } else {
                mapRequestsMeta.value.currentPage = 1;
            }


            defaultFilter.value = {
                searchField: mapRequestsInput.value.searchField ? KlipApi.SearchField[mapRequestsInput.value.searchField] : null,
                keyword: mapRequestsInput.value.query,
                searchOption: mapRequestsInput.value.mapRequestStatus !== 'allstatuses' ? mapRequestsInput.value.mapRequestStatus : '',
                date: {
                    from: DateUtil.parseToDate(mapRequestsInput.value.startDate),
                    to: DateUtil.parseToDate(mapRequestsInput.value.endDate),
                },
                archive: mapRequestsInput.value.isArchived ? mapRequestsInput.value.isArchived : false,
                location: mapRequestsInput.value.zoneGeometry ? JSON.parse(mapRequestsInput.value.zoneGeometry) : null,
            } as MapRequestSearchField;
            viewModelLoading.value = false;
        }

        onMounted(async () => {
            await _fetchSearchOprations();
        });

        return {
            viewModelLoading,
            mapRequestsSearchFields,
            mapRequestsSearchOptions,
            mapRequestDefaultSearchOption,
            mapRequestsMeta,
            mapRequestsFetching,
            mapRequestsColumns,
            mapRequests,
            defaultFilter,
            isAdmin,
            moveMapRequestChecked,
            searchMaprequests,
            hasCheckedMapRequest,
            checkedMapRequests,
            moveMapRequests,
            search,
            moveMapRequestsChanged,
            onMoveMapRequests,
            pagerClicked,
            checkboxChanged,
            columnClicked,
            rowClicked,
            clearSearch,
        };
    },
});
