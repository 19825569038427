import { computed, defineComponent, onMounted, ref, watch } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { isEmpty } from "lodash-es";
import {useUserStore} from "@/app/shared/state/UserDataModule";
import {EUserRole} from '@/app/shared/state/user-role';
import { escape } from 'html-escaper';
import {AdminOrganisationDetail} from "@/app/admin/admin-routes";
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useField, useForm} from 'vee-validate';
import {RouteRecordRaw} from 'vue-router';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';
import {Mri} from "@/app/mri/shared/services/services";
import {UpdateOrganisationActorInput} from "@/api/klip-api.proxy";

export default defineComponent({
    emits: ['update:refreshOrganisation'],
    props: {
        organisation: {
            type: Object as () => KlipApi.OrganisationDetail,
            required: true,
        }
    },
    setup(props, { emit }) {
        const togglePaysByInvoiceModal = 'toggle-pays-by-invoice';
        const invoiceDataModal = 'invoice-data';

        const klipApiProxy = useKlipApiProxy();
        const paysByInvoiceModalEvents = useKlModalEventsUtil(togglePaysByInvoiceModal, {
            onClosed() {
                isSending.value = false;
            },
        });
        const invoiceDataModalEvents = useKlModalEventsUtil(invoiceDataModal, {
            onClosed() {
                isSending.value = false;
            },
        });

        const form = useForm();
        const disableSubmit = computed((): boolean => !form.meta.value.valid);

        const emailField = useField<string>('E-mail', 'required|email|max:320');
        const attentionField = useField<string>('Ter attentie van', 'max:200');
        const referenceField = useField<string>('Eigen referentie', 'max:25');
        const invoiceNameField = useField<string>('Naam', 'max:200');
        const invoiceEmailField = useField<string>('Contactpersoon e-mail', 'email|max:320');
        const invoicePhoneNumberField = useField<string>('Telefoon', 'phone');

        const organisationInvoiceData = ref<KlipApi.OrganisationInvoiceData>(null);
        watch(() => organisationInvoiceData.value?.email, (newValue) => { emailField.value.value = newValue; },{ flush: 'sync' });
        watch(() => organisationInvoiceData.value?.attention, (newValue) => { attentionField.value.value = newValue; },{ flush: 'sync' });
        watch(() => organisationInvoiceData.value?.reference, (newValue) => { referenceField.value.value = newValue; },{ flush: 'sync' });
        watch(() => organisationInvoiceData.value?.invoiceName, (newValue) => { invoiceNameField.value.value = newValue; },{ flush: 'sync' });
        watch(() => organisationInvoiceData.value?.invoiceEmail, (newValue) => { invoiceEmailField.value.value = newValue; },{ flush: 'sync' });
        watch(() => organisationInvoiceData.value?.invoicePhoneNumber, (newValue) => { invoicePhoneNumberField.value.value = newValue; },{ flush: 'sync' });


        const viewModelLoading = ref<boolean>(true);
        const isSending = ref<boolean>(false);
        const isSendingDelete = ref<boolean>(false);
        const fetchingAthumiData = ref<boolean>(false);
        const kboInvalid = ref<boolean>(false);
        const switchingToOnlinePayment = ref<boolean>(false);
        const organisationPaysByInvoiceChanged = ref<boolean>(false);
        const organisationDetail = ref<KlipApi.OrganisationDetail>(null);
        const organisationPaysByInvoice = ref<boolean>(false);
        const athumiId = ref<string>('');

        const organisationChanged = (organisation: KlipApi.OrganisationDetail) => {
            organisationDetail.value = organisation;
        };

        const peppolInfo = computed((): string => {
            return organisationInvoiceData.value?.athumiData?.peppolInvoicing
                ? `Actief - ${organisationInvoiceData.value?.athumiData?.peppolIdentifier}`
                : 'Niet actief';
        });

        const subOrganisationsCount = computed(() => {
            if (!organisationDetail.value.organisationsDataTable) {
                return 0;
            }

            if (isEmpty(organisationDetail.value.organisationsDataTable) || organisationDetail.value.organisationsDataTable.length === 1) {
                return 0;
            }
            return organisationDetail.value.organisationsDataTable.length;
        });

        const prepaidAmount = computed((): string => {
            const formatter = new Intl.NumberFormat('nl-BE', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(organisationInvoiceData.value.prepaidAmount);
        });

        const isAdmin = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.admin]);
        });

        const switchToInvoiceDisabled = computed((): boolean => {
            if(!isAdmin.value) {
                return true;
            }
            return !organisationInvoiceData.value.athumiData.hasValidAthumiId
        });

        const hasValidAthumiId = computed((): boolean => {
            if(organisationInvoiceData.value.athumiData) {
                return organisationInvoiceData.value.athumiData.hasValidAthumiId
            }
            return false;
        });

        const athumiActor = computed((): KlipApi.OrganisatieAthumiData => {
           return organisationInvoiceData.value.athumiData;
        });

        const onSaveActor = async (newActor: KlipApi.OrganisatieAthumiData) => {

            isSending.value = true;

            const input = KlipApi.UpdateOrganisationActorInput.fromJS({});

            input.organisationId = props.organisation.organisationId;
            input.name = props.organisation.organisationName;

            input.vatNumber = newActor.vatNumber;

            input.street = newActor.straat;
            input.streetNumber = newActor.nummer;
            input.box = newActor.bus;
            input.zip = newActor.postcode;
            input.city = newActor.gemeente;
            input.countryCode = newActor.countryCode;
            input.invoiceEmail = newActor.emailAdressen[0];
            fetchingAthumiData.value = true;

            await klipApiProxy.operations_CreateOrUpdateOrganisationActor(input);

            emit('update:refreshOrganisation');

            isSending.value = false;
        }

        const routeAdminOrganisationDetail = computed((): RouteRecordRaw => {
            return AdminOrganisationDetail;
        });

        const _saveOrganisationInvoiceData = async () => {
            isSending.value = true;

            const input = KlipApi.UpdateOperationInvoiceDataInput.fromJS({})
            input.organisationId = props.organisation.organisationId;
            input.reference = organisationInvoiceData.value.reference;
            input.attention = organisationInvoiceData.value.attention;
            input.vatNumber = organisationInvoiceData.value.athumiData.vatNumber;
            input.email = organisationInvoiceData.value.email;
            input.updateChildOrganisations = true;
            input.invoiceName = organisationInvoiceData.value.invoiceName;
            input.invoiceEmail = organisationInvoiceData.value.invoiceEmail;
            input.invoicePhoneNumber = organisationInvoiceData.value.invoicePhoneNumber;

            await klipApiProxy.operations_UpdateOrganisationInvoiceDetail(input);
            isSending.value = false;
            organisationPaysByInvoice.value = true;
            organisationInvoiceData.value.hasInvoiceData = true;

            invoiceDataModalEvents.triggerModalToggle();
        };

        const saveOrganisationPaysByInvoice = async () => {
            if (organisationPaysByInvoice.value === false) {
                paysByInvoiceModalEvents.triggerModalToggle();

                isSending.value = true;
            } else {
                await switchToInvoice();
            }
        };

        const toggleCheckbox = (checked: boolean) => {
            organisationPaysByInvoice.value = checked;
            organisationPaysByInvoiceChanged.value = organisationPaysByInvoice.value !== organisationInvoiceData.value.paysByInvoice;
        };

        const changePaymentType = async () => {
            isSending.value = true;
            const input = KlipApi.UpdateOrganisationPaymentSettingsInput.fromJS({});
            input.organisationId = props.organisation.organisationId;
            input.paysByInvoice = organisationPaysByInvoice.value;
            await klipApiProxy.operations_UpdateOrganisationPaymentSettings(input);
            isSending.value = false;
        }

        const switchToOnlinePayment = async () => {
            switchingToOnlinePayment.value = true;
            await changePaymentType();
            organisationInvoiceData.value.paysByInvoice = organisationPaysByInvoice.value;
            switchingToOnlinePayment.value = false;

            paysByInvoiceModalEvents.triggerModalToggle();
        };

        const switchToInvoice = async () => {
            await changePaymentType();
            organisationInvoiceData.value.paysByInvoice = organisationPaysByInvoice.value;
        };

        const onOpenSwitchToInvoiceModal = () => {
            form.resetForm({ values: form.values });
            invoiceDataModalEvents.triggerModalToggle();
        }

        const cancelSwitchToOnlinePayment = () => {
            switchingToOnlinePayment.value = false;
            organisationPaysByInvoice.value = organisationInvoiceData.value.paysByInvoice;
            isSending.value = false;
        };

        const loadData = async () => {
            const invoiceData: KlipApi.EnvelopeOfOrganisationInvoiceData = await klipApiProxy.operations_GetOrganisationInvoiceDetail(props.organisation.organisationId);
            if (invoiceData.result) {
                organisationInvoiceData.value = invoiceData.result;
                organisationPaysByInvoice.value = invoiceData.result.paysByInvoice;

                if (invoiceData.result.athumiData && invoiceData.result.athumiData.hasValidAthumiId) {
                    fetchingAthumiData.value = false;
                    athumiId.value = invoiceData.result.athumiData.athumiId;
                } else {
                    athumiId.value = null;
                }
            }
            viewModelLoading.value = false;
        }


        onMounted(async () => {
            loadData();
        });

        watch(props.organisation, organisationChanged, { immediate: true, deep: true });

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_saveOrganisationInvoiceData, _onInvalidSubmit);

        return {
            emailField,
            attentionField,
            referenceField,
            invoiceNameField,
            invoiceEmailField,
            invoicePhoneNumberField,
            viewModelLoading,
            isSending,
            isSendingDelete,
            fetchingAthumiData,
            isAdmin,
            prepaidAmount,
            togglePaysByInvoiceModal,
            invoiceDataModal,
            athumiId,
            onSaveActor,
            peppolInfo,
            switchingToOnlinePayment,
            organisationDetail,
            organisationInvoiceData,
            organisationPaysByInvoice,
            organisationPaysByInvoiceChanged,
            subOrganisationsCount,
            routeAdminOrganisationDetail,
            toggleCheckbox,
            switchToOnlinePayment,
            switchToInvoice,
            onOpenSwitchToInvoiceModal,
            switchToInvoiceDisabled,
            hasValidAthumiId,
            cancelSwitchToOnlinePayment,
            saveOrganisationPaysByInvoice,
            athumiActor,
            onSubmit,
            disableSubmit,
        };
    }
});
