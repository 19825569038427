<script setup lang="ts">

import {ref, watch} from 'vue';
import KlDatepicker from '@/app/shared/components/kl-datepicker/kl-datepicker.vue';

    const myValue = ref(); // ref(['10.10.2024']);

    const onClick1 = (e: MouseEvent) => {
        myValue.value = ['01.01.2024'];
    }
    const onClick2 = (e: MouseEvent) => {
        myValue.value = ['02.02.2024'];
    }
    const onUpdate = (newValue) => {
        console.log('onUpdate', newValue);
    }
    const onInput = (newValue) => {
        console.log('onInput', newValue);
    }

    watch (myValue, (newValue) => {
        console.log('watch', newValue);
    })

</script>

<template>
    <div style="margin: 50px">
        {{myValue}}
        <vl-datepicker :placeholder="'test'" />
        <vl-datepicker v-model="myValue" placeholder="test" />
        <vl-datepicker :model-value="myValue" />
        <br/>
        <vl-datepicker :modelValue="myValue" @update:modelValue="onUpdate" />
        <vl-datepicker :value="myValue" @input="onInput" />

        <vl-button @click="onClick1">CLICK1</vl-button>
        <vl-button @click="onClick2">CLICK2</vl-button>

        <br/>
        <br/>
        kl-datepicker
        <kl-datepicker :model-value="myValue" />
        <kl-datepicker v-model="myValue" />
    </div>
</template>

<style scoped lang="scss">

</style>
