<template>
    <vl-datepicker
        v-bind="$attrs"
        placeholder="dd/mm/jjjj"
        visual-format="d/m/Y"
        format="d/m/Y"
        :modelValue="modelValue"
        @update:modelValue="onUpdate"
    />
</template>

<script lang="ts" src="./kl-datepicker.ts">
</script>

<!--<script lang="ts" src="./kl-datepicker.ts">-->
<!--</script>-->
