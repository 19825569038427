<div>
    <kl-search-maprequests mod-simple
        :search-fields="data.searchFields"
        :default-filter="defaultFilter"
        :search-options="data.searchOptions"
        :grouped-search-options="data.groupedSearchOptions"
        :default-search-option="defaultSearchOption"
        ref="searchMaprequests"
        @search="search"
        v-if="data">
        <template v-slot:zones v-if="data.zones && data.zones.length > 1">
            <vl-form-message-label for="zones-to-confirm" v-vl-visually-hidden>Zones</vl-form-message-label>
            <vl-select name="zones-to-confirm" v-model="selectedZone" mod-block>
                <option value="all">Alle zones</option>
                <option :value="option.key" v-for="option in data.zones">
                    {{ option.label }}
                </option>
            </vl-select>
        </template>
    </kl-search-maprequests>
    <div v-kl-u-background-alt v-kl-u-box-padding v-if="isUnaWriter">
        <vl-button @click="confirmCheckedMaprequests" :mod-disabled="!buttonEnabled" :mod-loading="confirmingMaprequests">Bevestig ontvangst</vl-button>
    </div>
    <vl-u-spacer mod-small />
    <ippro-datatable
        v-if="mapRequests"
        :meta="mapRequestsMeta"
        :dataset="mapRequests"
        :columns="mapRequestsColumns"
        mod-pagination-on-top
        :fetching="mapRequestsFetching"
        @checkbox-change="checkboxChanged"
        @pager-clicked="pagerClicked"
        @column-clicked="columnClicked"
        ref="mapRequestDatatable"
        :mod-checkable-rows="isUnaWriter"
        mod-clickable-rows
        mod-zebra
    >
        <template v-slot:noresults>
            <vl-u-spacer mod-large />
            <div v-vl-align:center>
                <template v-if="searchUsed">
                    <p>Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                    <vl-button @click="clearSearch" class="vl-button--link">Zoekvelden wissen</vl-button>
                    <vl-u-spacer mod-xsmall />
                </template>
                 <p v-else>U heeft al uw planaanvragen bevestigd.<br/>Ga naar uw te beantwoorden planaanvragen.</p>
            </div>
        </template>
    </ippro-datatable>
     <!-- confirm all -->
    <vl-modal id="confirm-all-modal" :closable="true">
        <vl-title tag-name="h3">Bent u zeker dat u alle planaanvragen voor alle organisaties wil bevestigen?</vl-title>
        <vl-typography>
            Dit kan niet ongedaan worden gemaakt.
        </vl-typography>
        <vl-action-group>
            <vl-button @click="confirmAll" v-vl-modal-toggle="'confirm-all-modal'" mod-large :mod-loading="confirmingMaprequests"
                :mod-disabled="confirmingMaprequests" :mod-error="!confirmingMaprequests">Ja, bevestig alle planaanvragen</vl-button>
            <vl-button class="vl-button--link" v-vl-modal-toggle="'confirm-all-modal'" icon="cross" mod-icon-before>
                Annuleer</vl-button>
        </vl-action-group>
    </vl-modal>
    <!-- ./delete-zone -->
</div>
